import approvedInvoiceDtls from './approvedInvoiceDtls';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  components: { approvedInvoiceDtls, DatePicker },
  data() {
    return {
      customerapprovedinvoices: 31,
      approvedkeyData: [],
      //approvedkeyData: [{totalapprovedinvoices:{{level_1}}+{{level_2}}+{{level_3}}+{{level_4}}+{{level_5}}+{{level_6}}+{{level_7}}}],
      loading: false,
      // showModal: false,
      // shoModal: false,
      invDateFrom: null,
      invDateTo: null,
      approvedkeyField: [
        {
          key: 'commercial_bucket',
          // label: '↓ Commercial/Financial →',
          label:
            '<span style="color: rgb(72, 177, 72);">↓ Commercial</span>/<span ">Financial →</span>',
          class: 'text-center wide-column'
        },
        {
          key: 'level_1',
          label: '00-05 Days',
          class: 'text-center wide-column-level'
        },
        {
          key: 'level_2',
          label: '06-10 Days',
          class: 'text-center wide-column-level'
        },
        {
          key: 'level_3',
          label: '11-15 Days',
          class: 'text-center wide-column-level'
        },
        {
          key: 'level_4',
          label: '16-20 Days',
          class: 'text-center wide-column-level'
        },
        {
          key: 'level_5',
          label: '21-31 Days',
          class: 'text-center wide-column-level'
        },
        // { key: 'level_6', label: '26-31Days'  ,class:"text-center" },
        {
          key: 'level_6',
          label: '31+ Days',
          class: 'text-center wide-column-level'
        },
        {
          key: 'totalapprovedinvoices',
          label: 'Total',
          class: 'text-center wide-column-level'
        }
        // { key: 'commercial_count', label: 'Commercial Count' ,class:"text-center maxWidth" },
      ],
      showApprInvDtlsModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      },
      rowMatrixIndexes: {
        f_lower: null,
        f_upper: null,
        c_lower: null,
        c_upper: null,
        from_date: null,
        to_date: null,
        fieldType: null
      },
      filedFlag: false,
      fieldType: null
    };
  },
  validations: {
    invDateFrom: { required },
    invDateTo: { required }
  },
  methods: {
    getSearchData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.filedFlag) {
          this.fieldType = 'Pending On Finance';
          this.getInprocessInvoicedata();
        } else {
          this.fieldType = 'Approved';
          this.getapprovedincoicedata();
        }
      }
    },
    getapprovedincoicedata() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      let payload = {
        from_date: this.invDateFrom,
        to_date: this.invDateTo
      };
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/getapprovedincoicedata', payload)
        .then(response => {
          if (response.status === 200) {
            this.approvedkeyData = response.data.data;
            for (let i = 0; i < this.approvedkeyData.length; i++) {
              const item = this.approvedkeyData[i];
              let totalApprovedInvoices = 0;

              totalApprovedInvoices += item.level_1;
              totalApprovedInvoices += item.level_2;
              totalApprovedInvoices += item.level_3;
              totalApprovedInvoices += item.level_4;
              totalApprovedInvoices += item.level_5;
              totalApprovedInvoices += item.level_6;
              // totalApprovedInvoices += (item.level_7);

              this.approvedkeyData[
                i
              ].totalapprovedinvoices = totalApprovedInvoices;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // }
    },
    getInprocessInvoicedata() {
      let payload = {
        from_date: this.invDateFrom,
        to_date: this.invDateTo
      };
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/getInprocessInvoicedata', payload)
        .then(response => {
          if (response.status === 200) {
            this.approvedkeyData = response.data.data;
            for (let i = 0; i < this.approvedkeyData.length; i++) {
              const item = this.approvedkeyData[i];
              let totalApprovedInvoices = 0;

              totalApprovedInvoices += item.level_1;
              totalApprovedInvoices += item.level_2;
              totalApprovedInvoices += item.level_3;
              totalApprovedInvoices += item.level_4;
              totalApprovedInvoices += item.level_5;
              totalApprovedInvoices += item.level_6;
              this.approvedkeyData[
                i
              ].totalapprovedinvoices = totalApprovedInvoices;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.invDateFrom);
    },
    openModal() {
      this.showModal = true;
    },
    clearAllData() {
      this.invDateFrom = null;
      this.invDateTo = null;
      this.approvedkeyData = [];
    },
    showInvoiceDtls(data) {
      let financialObj = this.bifurcateRange(data.field.label);
      let commercialObj = this.bifurcateRange(data.item.commercial_bucket);
      this.rowMatrixIndexes = {
        f_lower: financialObj.start,
        f_upper: financialObj.end,
        c_lower: commercialObj.start,
        c_upper: commercialObj.end,
        from_date: this.invDateFrom,
        to_date: this.invDateTo,
        fieldType: this.fieldType
      };
      this.showApprInvDtlsModal = true;
    },
    bifurcateRange(range) {
      let cleanedRange = range.replace('Days', '').trim();

      if (cleanedRange.includes('+')) {
        let start = parseInt(cleanedRange);
        return { start, end: null };
      }
      let [start, end] = cleanedRange.split('-').map(Number);
      return { start, end };
    },
    showHideApprInvDtlsModal(flag) {
      this.showApprInvDtlsModal = flag;
    },
    toggle() {
      this.filedFlag != this.filedFlag;
      this.approvedkeyData = [];
    }
    // objConvert(data) {
    //   const buckets = ["0-5 Days", "6-10 Days", "11-15 Days", "16-20 Days", "21-25 Days", "26-31 Days", "31+ Days"];

    //   return data.map(item => {
    //     const result = {
    //       "commercial_count": item.commercial_count,
    //       "commercial_bucket": item.commercial_bucket
    //     };

    //     buckets.forEach(bucket => {
    //       result[bucket] = 0;
    //     });

    //     result[item.finance_bucket] = item.finance_count;

    //     return result;
    //   });
    // }
  }
};
