import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'ApprovedInvoiceDtls',
  props: ['rowMatrixIndexes'],
  watch: {
    currentPage: function() {
      this.selectedInvoice();
    },
    perPage: function() {
      this.currentPage = 1;
      this.selectedInvoice();
    }
  },
  components: {},
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      appInvDtlsData: [],
      appInvDtlsFields: [
        {
          key: 'inv_num',
          label: 'Invoice Number',
          class: 'text-center'
        },
        {
          key: 'po_num',
          label: 'PO Number',
          class: 'text-center'
        },
        {
          key: 'approval_status',
          label: 'Approval Status',
          class: 'text-center'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'receipt_num',
          label: 'Receipt Number',
          class: 'text-center'
        },
        {
          key: 'submission_date',
          label: 'Submission Date',
          class: 'text-center'
        },
        {
          key: 'inv_amt',
          label: 'Invoice Amount',
          class: 'text-center'
        },
        {
          key: 'inv_date',
          label: 'Invoice Date',
          class: 'text-center'
        },
        {
          key: 'invoice_created_by',
          label: 'Invoice Created By',
          class: 'text-center'
        },
        {
          key: 'last_approver_before_finance',
          label: 'Last Approver Before Finance',
          class: 'text-center'
        },
        {
          key: 'last_action_date',
          label: 'Last Action Date',
          class: 'text-center'
        },
        {
          key: 'last_approver_fin',
          label: 'Last Approver Finance',
          class: 'text-center'
        },
        {
          key: 'last_action_fin',
          label: 'Last Action Finance',
          class: 'text-center'
        },
        {
          key: 'last_action_date_fin',
          label: 'Last Action Date Finance',
          class: 'text-center'
        }
      ],
      payload: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          if (this.rowMatrixIndexes.fieldType === 'Approved') {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'intWorkflow/getApprovedInvDtls',
              'Approved_Invoices',
              () => (this.loader = false)
            );
          } else {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'intWorkflow/getInprocessInvDtls',
              'Inprocess_Invoices',
              () => (this.loader = false)
            );
          }
        }
      }
    });
    if (this.rowMatrixIndexes) {
      if (this.rowMatrixIndexes.fieldType === 'Approved') {
        this.getApprovedInvDtls();
      } else {
        this.getInprocessInvDtls();
      }
    }
  },
  methods: {
    getApprovedInvDtls() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        c_lower: this.rowMatrixIndexes.c_lower,
        c_upper: this.rowMatrixIndexes.c_upper,
        f_lower: this.rowMatrixIndexes.f_lower,
        f_upper: this.rowMatrixIndexes.f_upper,
        from_date: this.rowMatrixIndexes.from_date,
        to_date: this.rowMatrixIndexes.to_date
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getApprovedInvDtls', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.appInvDtlsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getInprocessInvDtls() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        c_lower: this.rowMatrixIndexes.c_lower,
        c_upper: this.rowMatrixIndexes.c_upper,
        f_lower: this.rowMatrixIndexes.f_lower,
        f_upper: this.rowMatrixIndexes.f_upper,
        from_date: this.rowMatrixIndexes.from_date,
        to_date: this.rowMatrixIndexes.to_date
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getInprocessInvDtls', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.appInvDtlsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedInvoice(){
      if (this.rowMatrixIndexes.fieldType === 'Approved') {
        this.getApprovedInvDtls();
      } else {
        this.getInprocessInvDtls();
      }

    }
  },
  
  beforeDestroy() {
    this.unsubscribe();
  }
};
